<template>
  <el-form ref="form" :model="form" :rules="rules" label-width="96px">
    <div class="wrap" v-loading="loading" id="wrap">
      <topOperatingButton
        :disabled="disabled"
        :showAudit="showAudit"
        :isAddBtn="showAdd"
        @submitForm="submitForm"
        @addBill="addBill"
        @auditBill="auditBill"
        @handleAdd="handleAdd"
        @getQuit="getQuit"
        id="topOperatingButton"
      >
        <template slot="specialDiyBtn">
          <el-button size="mini" :disabled="single" @click="auditBill('终止')"
            >终止
          </el-button>
        </template>
      </topOperatingButton>
      <cardTitleCom
        cardTitle="基本信息"
        :billStatus="form.billStatus"
        id="basicInformation"
      >
        <template slot="cardContent">
          <div class="x-f marT10">
            <el-form-item label="单据编号" prop="billNo">
              <el-input
                class="inputWidth"
                size="mini"
                :disabled="true"
                v-model="form.billNo"
                placeholder="单据编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="单据日期" prop="billDate">
              <el-date-picker
                style="width: 250px"
                size="mini"
                v-model="form.billDate"
                type="date"
                placeholder="单据日期"
                :disabled="disabled"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="仓库" prop="outStoreId">
              <SelectRemote
                v-model="form.outStoreId"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listStore',
                    option: {
                      option: { disabled: disabled, remoteBody: { storeTypes: [1, 2] } },
                    },
                  }).option
                "
              />
            </el-form-item>
          </div>
          <div class="x-f marT10 marB10">
            <el-form-item label="单据备注" prop="remark">
              <el-input
                class="inputWidth"
                :disabled="disabled"
                v-model="form.billRemark"
                type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间"
                maxlength="80"
                :autosize="{ minRows: 1, maxRows: 1 }"
              />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="商品信息" class="headGoodsTable">
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="goodsMessage">
            <el-table
              :data="form.details"
              border
              show-summary
              :summary-method="getSummaries"
              @cell-mouse-enter="cellMouseEnter"
              @cell-mouse-leave="cellMouseLeave"
              :max-height="tableHeight"
              :height="tableHeight"
            >
              <el-table-column align="center" :label="tableCellLabel" width="80">
                <template v-slot="scope">
                  <i
                    v-if="scope.row.hoverRow"
                    :disabled="disabled"
                    @click="row('push', scope.$index)"
                    class="el-icon-circle-plus operatePush"
                  ></i>
                  <i
                    v-if="scope.row.hoverRow"
                    :disabled="disabled"
                    @click="row('del', scope.$index)"
                    class="el-icon-remove operateDel"
                  ></i>
                  <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                </template>
              </el-table-column>
              <el-table-column label="商品编码" width="160" align="center" prop="goodsNo">
                <template slot-scope="scope">
                  <SelectRemote
                    size="mini"
                    :disabled="disabled"
                    v-model="scope.row.goodsNo"
                    :option="optionGoods"
                    :filterable="true"
                    placeholder="选择商品" 
                    :ref="'goodsNo'+'_'+scope.$index +'_1'"
                    @handleSelectKeydown="handleTableKeydown($event, scope.row,'goodsNo',scope.$index,1)"
                  />
                </template>
              </el-table-column>
              <el-table-column
                label="商品名称"
                align="center"
                prop="goodsName"
                width="180"
              >
              </el-table-column>
              <el-table-column label="条码" width="180" align="center" prop="barcode">
              </el-table-column>
              <el-table-column label="规格" align="center" prop="goodsSpec" width="150">
                <template slot-scope="scope">
                  <el-form-item>
                    <el-select
                      @change="unitSalePriceChange($event, scope.row)"
                      v-model="scope.row.unitId"
                      :disabled="disabled"
                      :ref="'goodsSpec'+'_'+scope.$index +'_4'"
                      @keydown.native="handleTableKeydown($event, scope.row,'goodsSpec',scope.$index,4)"
                    >
                      <el-option
                        v-for="item in scope.row.units"
                        :key="item.unitId"
                        :label="item.goodsSpec"
                        :value="item.unitId"
                      />
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="单位" align="center" prop="unitName" width="120">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>单位</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'details.' + scope.$index + '.unitId'"
                    :rules="rules[`details.unitId`]"
                  >
                    <el-select
                      @change="unitSalePriceChange($event, scope.row)"
                      v-model="scope.row.unitId"
                      :disabled="disabled"
                      :ref="'unitName'+'_'+scope.$index +'_5'"
                      @keydown.native="handleTableKeydown($event,scope.row,'unitName', scope.$index,5)"
                    >
                      <el-option
                        v-for="item in scope.row.units"
                        :key="item.unitId"
                        :label="item.unitName"
                        :value="item.unitId"
                      />
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="数量" align="center" width="120">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>数量</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'details.' + scope.$index + '.unitQty'"
                    :rules="rules[`details.unitQty`]"
                  >
                    <el-input
                      type="number"
                      @keyup.native="onkeyupUnitQty(scope.row)"
                      v-model="scope.row.unitQty"
                      :disabled="disabled"
                      maxlength="7" 
                      :ref="'unitQty'+'_'+scope.$index +'_6'"
                      @keydown.native.up.prevent="handleTableKeydown($event,scope.row, 'unitQty',scope.$index,6)"
                      @keydown.native.down.prevent="handleTableKeydown($event,scope.row, 'unitQty',scope.$index,6)"
                      @keydown.native.left.prevent="handleTableKeydown($event,scope.row, 'unitQty',scope.$index,6)"
                      @keydown.native.right.prevent="handleTableKeydown($event,scope.row, 'unitQty',scope.$index,6)"
                      @keydown.native.enter.prevent="handleTableKeydown($event,scope.row, 'unitQty',scope.$index,6)"
                      @input="
                        limitInputlength(scope.row.unitQty, scope.row, 'unitQty', true)
                      "
                      @blur="onkeyupUnitQty(scope.row)"
                      :sunyunFormat="(v) => $syInput(1, v)"
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                label="单价(元)"
                align="center"
                width="120"
                prop="unitPrice"
                :formatter="(r, c, v) => $syInput(2, v)"
              >
              </el-table-column>
              <el-table-column
                label="金额(元)"
                align="center"
                width="120"
                prop="unitMoney"
              >
              </el-table-column>
              <el-table-column label="备注" align="center" prop="remark">
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.remark"
                    :disabled="disabled"
                    maxlength="80"
                    :ref="'remark'+'_'+scope.$index +'_9'" 
                    @keydown.native="handleTableKeydown($event,scope.row, 'remark',scope.$index,9)"
                  ></el-input>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </cardTitleCom>
      <SelectGoods
        :OpenGoods.sync="OpenGoods"
        :queryCode="queryCode"
        @getGoodS="getGoodS"
        ref="selectGoods"
      ></SelectGoods>
    </div>
  </el-form>
</template>

<script>
import SelectGoods from "@/views/components/selectGoodsUp";
import { listGoods } from "@/api/goods/goods"; //商品列表
import {
  addOrder,
  updateOrderStatus,
  getOrderDetail,
  updateOrder,
} from "@/api/shop/bill/waste"; //门店原料消耗
import { getNewDate } from "@/utils/newDate"; //到货时间
import { getBillNo } from "@/api/codeRule"; //单据号
import { unitSalePriceChange } from "@/utils/unitChange"; //多级单位切换函数
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import { limitInputlength, fcount } from "@/utils/index.js"; //保留几位小数
import tableKeydown from "@/utils/mixin/tableKeydown";
export default {
  name: "ScrapDetail",
  mixins: [tableKeydown],
  components: { SelectGoods, topOperatingButton, cardTitleCom, SelectRemote },
  data() {
    return {
      optionGoods: this.$select({
        key: "listGoods",
        option: {
          option: {
            noEcho: true,
            remoteBody: { goodsTypes: [2, 3] },
            change: (propValue, row) => {
              console.log(propValue, "propValue");
              console.log(row, "row");
              if (propValue) {
                const findIndex = this.form.details.findIndex(
                  (item) => item.goodsNo === propValue
                );
                let rows = JSON.parse(JSON.stringify(row));
                rows.findIndex = findIndex;
                this.getGoodS([rows]);
              } else {
                this.$nextTick(() => {
                  console.log("11");
                });
              }
            },
            tableChange: (propValue, row) => {
              this.getGoodS(row.check);
            },
            buttons: [
              {
                type: "more",
                option: {
                  title: "选择商品",
                  width: 1250,
                  type: "TreeAndTable",
                  formData: this.$dialog({
                    key: "goods",
                    option: {
                      queryParams: { goodsTypes: [2, 3] },
                      // treeApi: undefined,
                      table: {
                        ...this.$dialog({ key: "goods" }).table,
                      },
                    },
                  }),
                },
              },
            ],
          },
        },
      }).option,
      tableCellLabel: "序号",
      showAudit: true, //审核
      //打印
      PrintObj: {
        id: "print",
        popTitle: "打印的标题",
        extraCss: "https://www.google.com,https://www.google.com",
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
      },
      //选择商品信息开关
      OpenGoods: false,
      //传送商品参数
      queryCode: undefined,
      //当前表格宽度
      scrollLeft: null,
      //遮罩层
      loading: false,
      //标识已审核
      disabled: false,
      //终止
      single: true,
      //新增按钮
      showAdd: false,
      oldForm: {}, //旧的表单
      //表单数据
      form: {
        billStatus: "", //单据审核状态
        billNo: null, //单据编号
        billDate: null, //单据日期
        produceOrderId: null, //生产班次
        outStoreId: null, //调出仓库
        billRemark: null, //备注
        //单据表格-订单明细
        details: [{}],
      },
      //表单校验
      rules: {
        billNo: [
          {
            required: true,
            message: "请输入单据编号",
            trigger: ["blur", "change"],
          },
        ],
        billDate: [
          {
            required: true,
            message: "请选择单据日期",
            trigger: ["blur", "change"],
          },
        ],
        outStoreId: [
          {
            required: true,
            message: "请选择调出仓库",
            trigger: ["blur", "change"],
          },
        ],
        "details.unitId": [
          {
            required: true,
            message: "单位不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "details.unitQty": [
          {
            required: true,
            message: "数量不能为空",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^([1-9]\d*|0\.\d*[1-9]\d*|[1-9]\d*\.\d*[1-9]\d*)$/,
            message: "数值类型且不为0",
            trigger: ["blur", "change"],
          },
        ],
      },
      vivwH: 0, //页面高度
      topOperatingH: 0, //顶部按钮高度
      basicH: 0, //基本信息
      canKeyTableColumnList:[
        {key:'goodsNo',columnindex:1,componentRefName:'remoteSelect'},
        {key:'goodsSpec',columnindex:4,type:'select'},
        {key:'unitName',columnindex:5,type:'select'},
        {key:'unitQty',columnindex:6},
        {key:'remark',columnindex:9}
      ],//可以键盘导航的表格列详情
    };
  },
  watch: {
    //侦听是否开启关闭按钮
    "form.billStatus": {
      handler(newVal) {
        if (this.form.billStatus === "2") {
          //新增按钮
          this.showAdd = true;

          //打开关闭按钮
          this.single = false;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //打开审核按钮
          this.showAudit = false;
        } else if (this.form.billStatus === "3") {
          //新增按钮
          this.showAdd = false;
          //禁用关闭按钮
          this.single = true;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //禁用审核按钮
          this.showAudit = true;
        } else if (this.form.billStatus === "0") {
          //新增按钮
          this.showAdd = false;
          //打开输入按钮、保存按钮
          this.disabled = false;
          //禁用关闭按钮
          this.single = true;

          //禁用审核按钮
          this.showAudit = false;
        } else if (this.form.billStatus === "4") {
          //新增按钮
          this.showAdd = false;

          //禁用关闭按钮
          this.single = true;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //禁用审核按钮
          this.showAudit = true;
        } else if (this.form.billStatus === "") {
          //新增按钮
          this.showAdd = false;
          //禁用审核按钮
          this.showAudit = true;
          //禁用关闭按钮
          this.single = true;
          //打开输入按钮、保存按钮
          this.disabled = false;
        }
      },
      immediate: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loading = true;
      vm.reset();
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === "Update") {
        if (vm.$route.query.billId) {
          //获取单据详情
          const res1 = await getOrderDetail(vm.$route.query.billId);
          //单据赋值
          vm.form = res1.data;
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.form));
        }
      } else {
        await vm.getBasicData();
        vm.disabled = false;

        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != "{}") {
          vm.form = vm.oldForm;
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.form));
      }
      vm.loading = false;
    });
  },
  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.form.billId) {
      this.oldForm = {};
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.form));
    }
    next();
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.topOperatingH - this.basicH - 62;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.topOperatingH = document.getElementById("topOperatingButton").clientHeight;
      this.basicH = document.getElementById("basicInformation").clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    limitInputlength,
    handleResize() {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.topOperatingH = document.getElementById("topOperatingButton").clientHeight;
      this.basicH = document.getElementById("basicInformation").clientHeight;
    },
    unitSalePriceChange,
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      let eleIndex = this.form.details.indexOf(row);
      this.form.details[eleIndex].hoverRow = true;
      this.tableCellLabel = "操作";
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      // 移除hover的事件
      for (let index = 0; index < this.form.details.length; index++) {
        const element = this.form.details[index];
        element["hoverRow"] = false;
      }
      this.tableCellLabel = "序号";
    },
    //退出
    getQuit() {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          (item) => item.path === this.$route.path
        ),
        1
      );
      this.$router.push("/shop/bill/waste");
    },
    //新增按钮
    async handleAdd() {
      this.loading = true;
      this.reset();
      const res = await getBillNo(140308);
      this.form.billNo = res;
      this.loading = false;
    },
    //数量键盘抬起
    onkeyupUnitQty(row) {
      const unitMoney = this.$syInput(3, fcount([row.unitQty, row.unitPrice], "*"));
      this.$set(row, "unitMoney", unitMoney);
    },
    //表单重置
    async reset() {
      //表单数据
      this.form = {
        billStatus: "", //单据审核状态
        billNo: null, //单据编号
        billDate: null, //单据日期
        produceOrderId: null, //生产班次
        outStoreId: null, //调出仓库
        billRemark: null, //备注
        //单据表格-订单明细
        details: [{}],
      };
      this.resetForm("form");
      const res2 = await getBillNo(140308);
      this.form.billDate = getNewDate();
      this.form.billNo = res2;
    },
    //初始化页面
    async getBasicData() {
      //获取单据日期
      this.form.billDate = getNewDate();
      //新增时获取单据编号
      const res2 = await getBillNo(140308);
      this.form.billNo = res2;
    },
    //保存并新增单据
    async addBill() {
      this.loading = true;
      await this.submitForm(false);
      this.loading = false;
    },
    //订单明细表格合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        let name = "";
        if (index === 6) {
          name = "unitQty";
        } else if (index === 8) {
          name = "unitMoney";
          sums[index] += " 元";
        } else {
          sums[index] = "";
          return;
        }

        const values = data.filter((item) => !(item[`${name}`] === undefined));
        const values2 = values.map((i) => Number(i[`${name}`]));
        sums[index] = values2.reduce((prev, curr) => {
          return prev + curr;
        }, 0);
      });
      return sums;
    },

    //选中的商品
    getGoodS(value) {
      // this.form.details = this.form.details.filter(
      //   item =>
      //     (!(JSON.stringify(item) === '{}') && !(item.goodsId === undefined)) ||
      //     !(item.goodsId === undefined)
      // )
      // value.forEach(v => {
      //   this.$set(v, 'unitQty', 0)
      //   this.$set(v, 'unitMoney', 0)
      //   this.$set(v, 'unitPrice', v.purPrice)
      //   v.remark = ''
      //   v.unitMoney = v.unitQty * v.unitPrice
      // })
      // this.form.details.push(...value)

      let rows = JSON.parse(JSON.stringify(value));
      rows.forEach((v) => {
        // this.$set(v, "unitQty", 0);
        this.$set(v, "unitMoney", 0);
        this.$set(v, "unitPrice", v.purPrice);
        v.remark = "";
        v.unitMoney = (v.unitQty||0) * (v.unitPrice||0);
        v.hoverRow = false;
      });
      let findIndex = rows[0].findIndex;
      if (rows.length == 1 && findIndex != undefined) {
        console.log(rows[findIndex]);
        this.form.details[findIndex] = rows[0];
      } else {
        this.form.details.push(...rows);
      }
      this.form.details = this.form.details.filter(
        (item) => item.goodsNo != "" && item.goodsNo != undefined
      );
    },

    //表格单元格回车事件
    keyup(name, event, index) {
      //判断是否输入编码或条码
      if (this.form.details[`${index}`][`${name}`]) {
        listGoods({
          [name]: this.form.details[`${index}`][`${name}`],
          goodsTypes: [0, 2],
        }).then((response) => {
          // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
          if (response.total === 1) {
            event.target.blur();
            //原始赋值
            response.rows[0].remark = "";
            response.rows[0].unitQty = 0;
            response.rows[0].unitPrice = response.rows[0].purPrice;
            response.rows[0].unitMoney = 0;
            //使用vue的$set方法更新数组数据,使视图可编辑
            this.$set(this.form.details, index, response.rows[0]);
          } else {
            this.queryCode = this.form.details[`${index}`][`${name}`];
            // this.$refs.selectGoods.queryParams.goodsTypes = [2, 3]
            this.$refs.selectGoods.queryParams.goodsTypes = [0, 2];
            this.OpenGoods = true;
          }
        });
      } else {
        //没输入直接弹出商品信息框
        event.target.blur();
        // this.$refs.selectGoods.queryParams.goodsTypes = [2, 3]
        this.$refs.selectGoods.queryParams.goodsTypes = [0, 2];
        this.OpenGoods = true;
      }
    },

    //选择商品方法
    selectGoods() {
      // this.$refs.selectGoods.queryParams.goodsTypes = [2, 3]
      this.$refs.selectGoods.queryParams.goodsTypes = [0, 2];
      this.OpenGoods = true;
    },
    //表格增加/减少一行方法
    row(name, index) {
      if (name === "push") {
        this.form.details.splice(index + 1, 0, {
          goodsNo: "",
          hoverRow: false,
        });
      } else {
        if (this.form.details.length <= 1) return;
        this.form.details.splice(index, 1);
      }
    },
    //审核单据方法
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });

        if (name === "审核") {
          if (this.form.billStatus != "0") {
            this.$message.error("此单据不是未审核的状态哦~");
            return;
          }

          const obj = {
            billIds: [this.form.billId],
            billStatus: 2,
            billType: 140308,
          };
          await this.submitForm(true);
          //发送审核api
          const res = await updateOrderStatus(obj);
          this.form = res.data;
          // 弹出提示
          this.$message.success("审核成功");
          //禁用全部输入
          this.disabled = true;
        } else if (name === "反审核") {
          if (this.form.billStatus != "2") {
            this.$message.error("此单据不是已审核的状态哦~");
            return;
          }
          const obj = {
            billIds: [this.form.billId],
            billStatus: 0,
            billType: 140308,
          };
          //发送审核api
          const res = await updateOrderStatus(obj);
          this.form = res.data;
          // 弹出提示
          this.$message.success("反审核成功");
          //恢复全部输入
          this.disabled = false;
        } else if (name === "终止") {
          if (this.form.billStatus != "2") {
            this.$message.error("此单据不是未审核的状态哦~");
            return;
          }
          const obj = {
            billIds: [this.form.billId],
            billStatus: 4,
            billType: 140308,
          };
          //发送审核api
          const res = await updateOrderStatus(obj);
          this.form = res.data;
          // 弹出提示
          this.$message.success("单据关闭成功");
        }
      } catch {}
    },

    /** 提交按钮 */
    async submitForm(isBool) {
      if (
        !(JSON.stringify(this.form.details[0]) === "{}") &&
        !(this.form.details[0].goodsId === undefined)
      ) {
        const valid = await this.$refs.form.validate();
        // this.$refs['form'].validate(async valid => {
        if (valid) {
          if (this.form.billId) {
            const res = await updateOrder(this.form);
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.form = res.data;
              this.$modal.msgSuccess("修改单据成功");
            } else {
              this.reset();
              this.$modal.msgSuccess("保存并新增单据成功");
            }
          } else {
            const res = await addOrder(this.form);
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.form = res.data;
              this.$modal.msgSuccess("新增单据成功");
            } else {
              this.reset();
              this.$modal.msgSuccess("保存并新增单据成功");
            }
          }
        }
        // })
      } else {
        this.$message.error("单据明细不能为空");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  .headGoodsTable {
    //商品表格卡片
    .goodsMessage {
      padding: 6px 10px;
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
      ::v-deep .el-form-item__error {
        position: static;
      }
    }
  }
}
::v-deep .el-form-item {
  margin: 0;
  padding: 0;
}
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .el-input__suffix-inner {
  z-index: 11;
  height: 100%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
